import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Title from "../components/title"
import Button from "../components/button"
import Container from "../components/container"
import Product from "../components/product"
import { graphql, Link } from "gatsby"
import Seperator from "../components/seperator"
import Member from "../components/member"
import PostPreview from "../components/post-preview"
import styles from "./Index.module.scss"

export default function Home({ data }) {
  return (
    <Layout>
      <SEO />
      <Hero />
      <Container>
        <Title headingLevel="h3" className={styles.description}>
          Bünyemizdeki haddehanemizde ham madde ihtiyacını karşılayan
          firmamızda, standart ve özel bağlantı elemanları, soğuk dövme
          teknolojisi ile üretilmektedir.
        </Title>
        <section className={styles.products}>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Product
              key={node.frontmatter.slug}
              to={`/urunler/${node.frontmatter.slug}`}
              name={node.frontmatter.title}
              image={node.frontmatter.image.sharp.fluid}
            />
          ))}
          <Button to="/urunler" type="tertiary">
            TÜM ÜRÜNLER
          </Button>
        </section>
        <Seperator />
        <section className={styles.section}>
          <Title headingLevel="h2">Hakkimizda</Title>
          <p>
            Firmamız 1992 Senesinde bağlantı elemanları sektöründe hizmet
            vermeye başlamış piyasadaki özel bağlantı elemanları talebini ve
            açığını dikkate alarak çalışmalarını bu yöne yoğunlaştırmıştır.
            Kurulduğu günden bu yana kendini yenileyen teknolojiye ve insan
            kaynaklarına yatırım yapan bünyesindeki haddehanesinde hammadde
            ihtiyacını karşılayan firmamızda, standart ve özel bağlantı
            elemanları, soğuk dövme teknolojisi ile üretilmektedir. Üretimimizin
            büyük bir bölümü büro mobilyaları beyaz eşya, otomotiv ve bisiklet
            sektörüne yöneliktir. Ayrıca bu sektörlerin yan sanayilerine hizmet
            vermekte olup, benzer sektördeki firmaların standart ve özel
            bağlantı elemanları ihtiyacını titizlikle yapılmaktadır. Firmamız
            kuruluşundan bu yana başarı ile sunduğu ve geliştirdiği hizmetler
            sayesinde yerel, bölgesel ve ulusal düzeyde önemli başarılara adını
            yazdırmış ve ülke ekonomisine büyük katkılar sağlamıştır. Bu
            başarının ve gelişimin arkasında nitelikli insan kaynağımız, bilgi
            birikimimiz ve güvene dayalı iş ilişkilerimiz yer almaktadır.
            İşbirliği içerisinde olduğumuz tüm kurum ve kuruluşlarla tesis etmiş
            olduğumuz karşılıklı güven, çalışma anlayışımızın en önemli değerini
            oluşturmaktadır. Güvene dayalı güçlü ilişkilerimizin bundan sonraki
            süreçte de tüm paydaşlarımız ile sürdürülmesi en önemli gayretimiz
            olacaktır. Çalışmalarında kaliteyi sürekli iyileştirmeyi ve
            başarılarını sürekli kılmayı hedefleyen şirketimiz, kurumsal yapısı
            içinde ISO 9001:2008 Kalite Yönetim Sistemi’ni uygulamaktadır.
          </p>
        </section>
        <Seperator />
        <section className={styles.section}>
          <Title headingLevel="h2">Haberler</Title>
          <div className={styles.posts}>
            {data.haberler &&
              data.haberler.edges.map(({ node }) => (
                <PostPreview
                  title={node.frontmatter.title}
                  slug={node.frontmatter.slug}
                  image={node.frontmatter.image.sharp.fluid}
                />
              ))}
          </div>
        </section>
      </Container>
      <section className={styles.highlight}>
        <Title headingLevel="h3">
          “Özel bağlantı elemanlarında, ihtiyaclarınıza kaliteli ve hızlı
          çözümler…”
        </Title>
      </section>
      <Container>
        <section className={styles.section}>
          <Title headingLevel="h2">Ekibimiz</Title>
          <div className={styles.team}>
            <Member
              name="Mehmet Vatansever"
              title="Üretim Müdürü"
              image={data.mehmet.sharp.fluid}
            />
            <Member
              name="Zafer Vatansever"
              title="Satış ve Pazarlama"
              image={data.zafer.sharp.fluid}
            />
            <Member
              name="Mustafa Vatansever"
              title="Üretim Sorumlusu"
              image={data.mustafa.sharp.fluid}
            />
          </div>
        </section>

        <section className={styles.section}>
          <Title headingLevel="h2">İletişim</Title>
          <div>
            <Title headingLevel="p">
              Projeleriniz ile ilgili ya da aklınıza takılan herhangi bir konu
              ile alakalı olarak bizimle aşağıdaki iletişim araçları ile
              irtibata geçebilirsiniz.
            </Title>
            <p>
              +90 (212) 672 13 80 no lu telefon numaramızı arayabilir,{" "}
              <Link to="/iletisim">iletişim </Link>
              sayfamızdan mesaj gönderebilir ya da{" "}
              <a href="mailto:info@ozelcivata.com">info@ozelcivata.com</a> mail
              adresimize mail atabilirsiniz.
            </p>
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/products/**/*.md" } }
      limit: 6
      sort: { order: ASC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            image {
              sharp: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    mehmet: file(relativePath: { eq: "team/mehmet-vatansever.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    zafer: file(relativePath: { eq: "team/zafer-vatansever.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    mustafa: file(relativePath: { eq: "team/mustafa-vatansever.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    haberler: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/blog/**/*.md" } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            image {
              sharp: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
