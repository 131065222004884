import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, StaticQuery } from "gatsby"
import Container from "../container"
import Title from "../title"
import Button from "../button"
import styles from "./hero.module.scss"

const Hero = () => (
  <StaticQuery
    query={graphql`
      query {
        hero: file(relativePath: { eq: "ozel-civata-ozel-parcalar-1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.hero.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={styles.hero}
          fluid={imageData}
        >
          <div className={styles.container}>
            <Container>
              <Title headingLevel="h2">
                Bağlantı Elemanlarında
                <br />
                Özeli Üretiyoruz
              </Title>
              <Title headingLevel="p">
                1992'den beri otomotiv, beyaz eşya, mobilya, inşaat, bisiklet ve
                makine üretim sektöründe özeli üretiyoruz...
              </Title>
              <Button to="/urunler" type="tertiary" className={styles.button}>
                Ürünleri İncele
              </Button>
            </Container>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

export default Hero
