import React from "react"
import Img from "gatsby-image"
import Title from "../title"
import styles from "./member.module.scss"

export default function Member({ name, title, image }) {
  return (
    <div className={styles.member}>
      <Img fluid={image} alt={name} />
      <Title headingLevel="h4">{name}</Title>
      <p>{title}</p>
    </div>
  )
}
