import React from "react"
import Img from "gatsby-image"
import Title from "../title"
import Button from "../button"
import { Link } from "gatsby"

export default function PostPreview({ title, slug, image }) {
  return (
    <Link to={`/${slug}`}>
      <Img fluid={image} alt={title} />
      <Title headingLevel="h3">{title}</Title>
      <Button type="secondary">Devamini Oku</Button>
    </Link>
  )
}
